<template>
  <div v-if="showMobileTabBar">
    <router-view class="view-container" />
    <div class="navigation-container">
      <v-btn
        rounded
        depressed
        class="selected"
        :color="selectedPage != 0 ? 'transparent' : 'rgba(255, 255, 255, 0.32)'"
        @click="selectPage(0)"
        height="44">
        <v-img
          contain
          :src="require('@/assets/navigation-icons/home.svg')" />
      </v-btn>
      <v-btn
        rounded
        depressed
        class="selected"
        :color="selectedPage != 1 ? 'transparent' : 'rgba(255, 255, 255, 0.32)'"
        @click="selectPage(1)"
        height="44">
        <v-img
          contain
          :src="require('@/assets/navigation-icons/search.svg')" />
      </v-btn>
      <v-btn
        rounded
        depressed
        class="selected"
        :color="selectedPage != 2 ? 'transparent' : 'rgba(255, 255, 255, 0.32)'"
        @click="selectPage(2)"
        height="44">
        <v-img
          contain
          :src="require('@/assets/navigation-icons/user.svg')" />
      </v-btn>
    </div>
  </div>
  <router-view v-else />
</template>

<script>
  import ProtectedRoutesViewMixin from '@/layouts/protected-routes-view/ProtectedRoutesView.mixin.vue';
  export default {
    mixins: [ProtectedRoutesViewMixin],
  };
</script>

<style lang="css">
  .navigation-container {
    width: fit-content;
    height: 44px;
    position: fixed;
    bottom: 4vh;
    left: 50%;
    transform: translateX(-50%);
    background: var(--v-primaryColor-base);
    box-shadow: -2px 12px 25px 0px rgba(var(--v-primaryColor-rgb), 0.64);
    border-radius: 32px;
  }
  .view-container {
    margin-bottom: 80px;
  }
  .selected {
    min-width: 0px !important;
    /* padding: 10px 14px !important; */
    background: rgba(255, 255, 255, 0.32) !important;
  }
</style>
